import React from "react";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";

class Footer extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <TweenOne
            animation={{ y: "+=30", opacity: 0, type: "from" }}
            key="footer"
            {...dataSource.copyright}
          >
            <div className="lebon-logoe">
              <svg
                width="172"
                height="203"
                viewBox="0 0 172 203"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M171.215 49.1066L112.384 31.9693C112.555 34.1746 112.63 36.432 112.63 38.7453V39.1746L163.5 53.992V142.151H171.215V50.2026H170.896L171.215 49.1066Z"
                  fill="#F04D44"
                />
                <path
                  d="M171.215 201.719H163.501V142.151H171.215V201.719Z"
                  fill="#F04D44"
                />
                <path
                  d="M18.0447 199.744H0.197998V196.427H18.0447V199.744Z"
                  fill="#737577"
                />
                <path
                  d="M0.19812 177.488H18.0448V179.182C18.0448 179.444 18.0021 179.663 17.9155 179.84C17.8288 180.015 17.6821 180.186 17.4768 180.348L5.57945 189.622C6.12212 189.574 6.62345 189.548 7.08479 189.548H18.0448V192.46H0.19812V190.728C0.19812 190.587 0.203453 190.467 0.216787 190.366C0.228787 190.262 0.25412 190.174 0.29012 190.095C0.327453 190.016 0.380787 189.942 0.45012 189.868C0.520787 189.794 0.61412 189.712 0.728787 189.622L12.6755 180.311C12.3875 180.338 12.1035 180.358 11.8235 180.374C11.5448 180.39 11.2848 180.398 11.0461 180.398H0.19812V177.488Z"
                  fill="#737577"
                />
                <path
                  d="M0.19812 154.272H18.0448V157.184H6.51679C6.05679 157.184 5.55945 157.16 5.02345 157.111L15.1568 162.477C15.6421 162.733 15.8848 163.121 15.8848 163.644V164.111C15.8848 164.636 15.6421 165.024 15.1568 165.277L4.98612 170.707C5.25812 170.684 5.52612 170.663 5.78879 170.647C6.05279 170.629 6.29545 170.621 6.51679 170.621H18.0448V173.532H0.19812V171.037C0.19812 170.892 0.20212 170.764 0.21012 170.657C0.21812 170.551 0.239453 170.455 0.271453 170.368C0.304787 170.283 0.35812 170.207 0.432787 170.136C0.50612 170.067 0.604787 169.999 0.728787 169.933L10.6395 164.616C10.9021 164.475 11.1741 164.348 11.4541 164.228C11.7341 164.111 12.0221 163.993 12.3181 163.879C12.0128 163.764 11.7195 163.644 11.4355 163.521C11.1515 163.4 10.8781 163.269 10.6141 163.129L0.728787 157.884C0.604787 157.819 0.50612 157.748 0.432787 157.676C0.35812 157.603 0.304787 157.523 0.271453 157.436C0.239453 157.351 0.21812 157.253 0.21012 157.147C0.20212 157.04 0.19812 156.915 0.19812 156.767V154.272Z"
                  fill="#737577"
                />
                <path
                  d="M9.12133 136.759C8.14267 136.759 7.264 136.89 6.48533 137.152C5.708 137.414 5.048 137.787 4.50533 138.27C3.96267 138.752 3.54667 139.34 3.25867 140.032C2.97067 140.724 2.82667 141.504 2.82667 142.371C2.82667 143.24 2.97067 144.019 3.25867 144.712C3.54667 145.404 3.96267 145.994 4.50533 146.48C5.048 146.967 5.708 147.342 6.48533 147.606C7.264 147.866 8.14267 147.996 9.12133 147.996C10.1 147.996 10.9787 147.866 11.756 147.606C12.5347 147.342 13.192 146.967 13.7307 146.48C14.2693 145.994 14.6827 145.404 14.972 144.712C15.26 144.019 15.4027 143.24 15.4027 142.371C15.4027 141.504 15.26 140.724 14.972 140.032C14.6827 139.34 14.2693 138.752 13.7307 138.27C13.192 137.787 12.5347 137.414 11.756 137.152C10.9787 136.89 10.1 136.759 9.12133 136.759ZM9.12133 133.382C10.4293 133.382 11.6413 133.596 12.756 134.026C13.8707 134.455 14.836 135.064 15.6493 135.85C16.464 136.636 17.1 137.582 17.5573 138.687C18.0133 139.792 18.2413 141.02 18.2413 142.371C18.2413 143.715 18.0133 144.939 17.5573 146.046C17.1 147.15 16.464 148.098 15.6493 148.887C14.836 149.678 13.8707 150.29 12.756 150.726C11.6413 151.158 10.4293 151.376 9.12133 151.376C7.81333 151.376 6.60133 151.158 5.48667 150.726C4.37067 150.29 3.40667 149.678 2.592 148.887C1.77733 148.098 1.14267 147.15 0.685333 146.046C0.229333 144.939 0 143.715 0 142.371C0 141.471 0.105333 140.623 0.316 139.83C0.525333 139.035 0.821333 138.306 1.204 137.643C1.58667 136.98 2.05067 136.384 2.59867 135.855C3.14533 135.328 3.75733 134.879 4.43067 134.511C5.10533 134.142 5.83867 133.863 6.628 133.671C7.41867 133.478 8.24933 133.382 9.12133 133.382"
                  fill="#737577"
                />
                <path
                  d="M15.4527 123.825C15.4527 123.212 15.378 122.696 15.23 122.277C15.082 121.86 14.8847 121.527 14.638 121.277C14.3913 121.027 14.1033 120.848 13.774 120.736C13.4447 120.627 13.0953 120.572 12.7247 120.572C12.338 120.572 11.99 120.631 11.682 120.755C11.374 120.877 11.11 121.071 10.8927 121.332C10.674 121.595 10.5087 121.932 10.3927 122.345C10.278 122.759 10.2193 123.256 10.2193 123.837V127.167H15.4527V123.825ZM2.76466 127.167H7.92466V124.537C7.92466 123.408 7.718 122.555 7.30733 121.977C6.89533 121.4 6.242 121.111 5.34466 121.111C4.41533 121.111 3.75266 121.373 3.358 121.897C2.962 122.42 2.76466 123.241 2.76466 124.353V127.167ZM0.197998 124.353C0.197998 123.191 0.308665 122.196 0.531331 121.368C0.753998 120.543 1.07 119.864 1.48066 119.337C1.89266 118.808 2.39133 118.421 2.97533 118.176C3.55933 117.931 4.218 117.807 4.95 117.807C5.37 117.807 5.77 117.868 6.15266 117.992C6.53533 118.115 6.894 118.304 7.22733 118.563C7.56066 118.82 7.862 119.148 8.134 119.545C8.40466 119.943 8.63533 120.415 8.82466 120.964C9.37666 118.524 10.7007 117.303 12.7993 117.303C13.5567 117.303 14.2553 117.447 14.8967 117.733C15.5393 118.02 16.0927 118.437 16.558 118.987C17.022 119.535 17.386 120.209 17.6487 121.013C17.9127 121.816 18.0447 122.733 18.0447 123.764V130.469H0.197998V124.353Z"
                  fill="#737577"
                />
                <path
                  d="M18.0447 114.294H0.197998V110.978H18.0447V114.294Z"
                  fill="#737577"
                />
                <path
                  d="M15.3043 96.608H18.0443V107.012H0.197632V103.708H15.3043V96.608Z"
                  fill="#737577"
                />
                <path
                  d="M18.0447 94.2505H0.197998V90.9346H18.0447V94.2505Z"
                  fill="#737577"
                />
                <path
                  d="M11.3056 77.5832L5.36963 79.7458C5.10563 79.8512 4.79363 79.9618 4.43097 80.0765C4.06963 80.1912 3.67897 80.3058 3.25897 80.4205C3.67897 80.5272 4.07097 80.6378 4.43763 80.7512C4.8043 80.8672 5.12297 80.9778 5.39363 81.0832L11.3056 83.2325V77.5832ZM18.0443 71.7498V74.3045C18.0443 74.5898 17.9723 74.8245 17.8283 75.0032C17.6843 75.1845 17.5056 75.3192 17.2923 75.4098L13.651 76.7352V84.0925L17.2923 85.4192C17.4803 85.4845 17.6536 85.6112 17.8096 85.8005C17.967 85.9885 18.0443 86.2218 18.0443 86.5005V89.0805L0.197632 82.0912V78.7258L18.0443 71.7498Z"
                  fill="#737577"
                />
                <path
                  d="M8.68915 64.5396C8.68915 63.9183 8.61049 63.3743 8.45449 62.9129C8.29849 62.4503 8.08249 62.0689 7.80649 61.7703C7.53182 61.4716 7.20382 61.2476 6.82649 61.1009C6.44782 60.9529 6.03182 60.8796 5.57849 60.8796C4.67449 60.8796 3.97849 61.1769 3.49315 61.7703C3.00782 62.3636 2.76515 63.2703 2.76515 64.4903V66.6036H8.68915V64.5396ZM18.0452 56.0396V59.0249C18.0452 59.5903 17.8225 59.9996 17.3785 60.2529L11.6638 63.9863C11.4505 64.1263 11.2985 64.2796 11.2078 64.4476C11.1172 64.6156 11.0718 64.8636 11.0718 65.1903V66.6036H18.0452V69.9076H0.198486V64.4903C0.198486 63.2876 0.32382 62.2529 0.574486 61.3889C0.825153 60.5249 1.17715 59.8169 1.63049 59.2636C2.08249 58.7116 2.62382 58.3049 3.25315 58.0423C3.88249 57.7809 4.57582 57.6503 5.33182 57.6503C5.93315 57.6503 6.50115 57.7369 7.03582 57.9129C7.57049 58.0903 8.05582 58.3436 8.49182 58.6756C8.92782 59.0063 9.31049 59.4156 9.63982 59.9023C9.96915 60.3903 10.2278 60.9449 10.4172 61.5676C10.5412 61.3543 10.6865 61.1583 10.8558 60.9783C11.0238 60.7983 11.2238 60.6343 11.4545 60.4863L18.0452 56.0396Z"
                  fill="#737577"
                />
                <path
                  d="M18.0447 54.1132H0.197998V50.7972H18.0447V54.1132Z"
                  fill="#737577"
                />
                <path
                  d="M11.3056 37.4452L5.36963 39.6078C5.10563 39.7132 4.79363 39.8238 4.43097 39.9385C4.06963 40.0532 3.67897 40.1678 3.25897 40.2825C3.67897 40.3892 4.07097 40.4998 4.43763 40.6132C4.8043 40.7292 5.12297 40.8398 5.39363 40.9452L11.3056 43.0945V37.4452ZM18.0443 31.6118V34.1665C18.0443 34.4518 17.9723 34.6865 17.8283 34.8652C17.6843 35.0465 17.5056 35.1812 17.2923 35.2718L13.651 36.5972V43.9545L17.2923 45.2812C17.4803 45.3465 17.6536 45.4732 17.8096 45.6625C17.967 45.8505 18.0443 46.0838 18.0443 46.3625V48.9425L0.197632 41.9532V38.5878L18.0443 31.6118Z"
                  fill="#737577"
                />
                <path
                  d="M36.6825 194.716L32.2545 194.723L32.2305 179.609L35.6758 179.604C39.1892 179.599 40.6692 181.003 40.6758 185.572L40.6825 189.859C40.6878 193.515 39.2838 194.712 36.6825 194.716ZM32.1998 159.576L35.9265 159.571C38.6665 159.567 39.8652 161.111 39.8705 164.625L39.8745 167.367C39.8798 171.303 38.1252 172.571 35.2425 172.576L32.2198 172.579L32.1998 159.576ZM42.6278 175.517C46.0705 173.895 47.6105 170.659 47.6025 165.597L47.5998 163.84C47.5892 156.248 44.1398 152.528 36.1265 152.541L24.4585 152.559L24.5332 201.763L36.6932 201.744C44.4252 201.733 48.4265 197.649 48.4145 190.059L48.4078 186.051C48.3998 180.851 46.7772 177.127 42.6278 175.517"
                  fill="#1BAAA1"
                />
                <path
                  d="M70.3049 195.28C67.8449 195.284 66.2969 193.951 66.2916 190.436L66.2502 163.864C66.2449 160.351 67.7889 159.015 70.2489 159.011C72.7102 159.007 74.2582 160.339 74.2636 163.853L74.3049 190.424C74.3089 193.939 72.7649 195.276 70.3049 195.28ZM70.2396 151.98C62.6476 151.992 58.5062 156.496 58.5196 164.368L58.5582 189.956C58.5702 197.828 62.7236 202.321 70.3156 202.309C77.9076 202.299 82.0476 197.793 82.0356 189.92L81.9969 164.333C81.9849 156.461 77.8302 151.969 70.2396 151.98"
                  fill="#1BAAA1"
                />
                <path
                  d="M80.2196 95.3501L59.1329 95.3821L59.2076 144.587L80.2943 144.554L80.2836 137.526L66.9289 137.546L66.9063 123.135L77.5209 123.119L77.5103 116.091L66.8956 116.106L66.8743 102.399L80.2303 102.379L80.2196 95.3501Z"
                  fill="#1BAAA1"
                />
                <path
                  d="M116.344 7.13335V1.87906e-05L109.496 1.99468L24.396 26.784V87.3907H32.1107V31.6707L109.496 9.12935V144.587H116.344V7.13335Z"
                  fill="#1BAAA1"
                />
                <path
                  d="M24.396 119.828H24.496L24.5347 144.587L44.9893 144.556L44.9787 137.528L32.256 137.547L32.1907 95.3707H32.1107V94.804H24.396V119.828Z"
                  fill="#1BAAA1"
                />
                <path
                  d="M109.496 151.719V154.053H109.545L109.588 181.987L101.6 152.547L91.8997 152.561L91.9743 201.765L98.933 201.755L98.8797 166.117L108.562 201.741L116.506 201.729L116.432 152.524H116.344V151.719H109.496Z"
                  fill="#1BAAA1"
                />
              </svg>
            </div>
            {dataSource.copyright.children}
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;
